<template>
  <div>
    <div class="mb-2 font-large-1 h1">
      Greetings! Outlet {{ userData.name }},
    </div>
    <b-card class="p-lg-1 mb-1 mx-n3 rounded-0">
      <div class="row justify-content-center align-items-center">
        <div class="col-xs-10 col-sm-10 col-md-7 col-lg-7">
          <img
            :src="require('@/assets/images/logo/delivery.png')"
            class="img-fluid"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
          <DeliveryPrice />
        </div>
      </div>
    </b-card>

    <!-- Guideline -->
    <b-row class="mx-1 my-2">
      <h2 class="text-primary font-large-1 d-none d-sm-inline-block">
        Guidelines
      </h2>
    </b-row>

    <b-row>
      <b-col
        v-for="(guidelineItem, index) in guidelineList"
        :key="index"
        cols="12"
        lg="4"
        class="mb-2"
      >
        <Guideline :guideline-item="guidelineItem" />
      </b-col>
    </b-row>
    <!-- Policy Modal -->
    <b-modal
      id="policy-modal"
      title="Policy Notice"
      hide-footer
      hide-header
      size="lg"
      no-close-on-backdrop
    >
      <div class="p-2">
        <h1 class="text-primary">Terms and Conditions</h1>
        <b-row class="mb-2 mt-3">
          <b-col>
            <b-button
              @click="
                openModal(
                  'https://docs.google.com/gview?url=https://gologspace.sgp1.cdn.digitaloceanspaces.com/misc_files/REFUND_POLICY.docx&embedded=true',
                  'Refund Policy'
                )
              "
              variant="outline-primary"
              class="w-100 text-primary custom-button"
            >
              Refund Policy
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col>
            <b-button
              @click="
                openModal(
                  'https://docs.google.com/gview?url=https://gologspace.sgp1.cdn.digitaloceanspaces.com/misc_files/DATA_PRIVACY_POLICY.docx&embedded=true',
                  'Data Privacy Policy'
                )
              "
              variant="outline-primary"
              class="w-100 text-primary custom-button"
            >
              Data Privacy Policy
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col>
            <b-button
              @click="
                openModal(
                  'https://docs.google.com/gview?url=https://gologspace.sgp1.cdn.digitaloceanspaces.com/misc_files/RULES_CONDUCT_POLICY.docx&embedded=true',
                  'Rules & Conduct Policy'
                )
              "
              variant="outline-primary"
              class="w-100 text-primary custom-button"
            >
              Rules & Conduct Policy
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col>
            <b-button
              @click="
                openModal(
                  'https://docs.google.com/gview?url=https://gologspace.sgp1.cdn.digitaloceanspaces.com/misc_files/PRIVACY_TERMS_POLICY.docx&embedded=true',
                  'Privacy Terms Policy'
                )
              "
              variant="outline-primary"
              class="w-100 text-primary custom-button"
            >
              Privacy Terms Policy
            </b-button>
          </b-col>
        </b-row>
        <b-row class="justify-content-end align-items-center">
          <b-col cols="auto" class="d-flex align-items-center">
            <span
              @click="rejectPolicy"
              class="text-primary"
              style="cursor: pointer"
            >
              Cancel
            </span>
          </b-col>
          <b-col cols="auto">
            <b-button variant="primary" @click="acceptPolicy">Accept</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <b-modal v-model="showModal" :title="modalTitle" size="lg" hide-footer>
      <iframe
        :src="docUrl"
        width="100%"
        height="750px"
        frameborder="0"
      ></iframe>
    </b-modal>
  </div>
</template>

<script>
import { getUserData } from '@/auth/utils';
import Guideline from './Guideline.vue';
import DeliveryPrice from './DeliveryPrice.vue';
import useJwt from '@/auth/jwt/useJwt';

export default {
  components: {
    DeliveryPrice,
    Guideline,
  },

  data() {
    return {
      userData: {},
      guidelineList: [
        {
          label: 'Shipment',
          headers: ['Type of Shipment', 'Order ID'],
          items: [
            ['Outlet', 'F000000'],
            ['B2C', 'D000000'],
            ['B2BPRO', 'B000000'],
            ['DHL', 'DHL ID'],
            ['Singapore', 'S000000'],
          ],
        },
        {
          label: 'Logpoint',
          headers: ['Color', 'Description'],
          items: [
            ['Green', 'Good'],
            ['Orange', 'Running Low'],
            ['Red', 'Empty'],
          ],
        },
        {
          label: 'Client',
          headers: ['Category', 'Description'],
          items: [
            ['Silver', 'Basic'],
            ['Gold', '.............'],
            ['Platinum', 'Premium'],
          ],
        },
      ],
      policy: null,
      showModal: false,
      docUrl: '',
      modalTitle: '',
    };
  },

  created() {
    this.userData = getUserData();
    this.checkPolicy();
  },
  watch: {
    policy(newPolicy) {
      if (newPolicy === 1) {
        this.$bvModal.show('policy-modal');
      }
    },
  },

  methods: {
    openModal(url, title) {
      this.docUrl = url;
      this.modalTitle = title;
      this.showModal = true;
    },
    checkPolicy() {
      const policy = JSON.parse(localStorage.getItem('policy'));
      this.policy = policy;
    },
    acceptPolicy() {
      // Remove the policy from storage
      localStorage.removeItem('policy');

      // Hide the modal
      this.$bvModal.hide('policy-modal');
    },
    rejectPolicy() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
      localStorage.removeItem('userData');
      this.$router.push({ name: 'auth-login' });
    },
  },
};
</script>

<style scoped>
.custom-button {
  font-weight: bold;
  font-size: 1.2em;
  border-color: lightgray !important;
}
</style>
