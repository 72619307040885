<template>
  <b-container class="rounded box-shadow-1 d-flex justify-content-center w-75">
    <validation-observer ref="simpleRules">
      <b-card
        class="d-flex w-100 px-0 justify-content-center align-items-center"
      >
        <b-card-text class="h1 mb-1 font-large-1 text-center text-primary">
          Quote Delivery Price?
        </b-card-text>
        <!-- <hr class="border-3 divider-primary"/> -->
        <hr />
        <b-row class="mb-2">
          <b-col class="col-16">
            <b-row>
              <b-col class="col-12 col-lg-6 w-100">
                <b-form-group label="Origin Postcode">
                  <validation-provider
                    #default="{ errors }"
                    name="Postcode Origin"
                    rules="required|digits:5"
                    class="w-100"
                  >
                    <b-form-input
                      v-model="form.postcode_origin"
                      name="Postcode Origin"
                      type="text"
                      placeholder="From Postcode"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" lg="6">
                <validation-provider
                  #default="{ errors }"
                  name="Postcode Destination"
                  rules="required|digits:5"
                  class="w-100"
                >
                  <b-form-group label="Destination Postcode">
                    <b-form-input
                      v-model="form.postcode_destination"
                      type="text"
                      placeholder="Destination Postcode"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col class="col-16">
            <b-row>
              <b-col class="col-12 col-lg-6 w-100">
                <b-form-group label="Origin State">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Origin State"
                    vid="state"
                    rules="required"
                  >
                    <vSelect
                      v-model="originState"
                      :reduce="(states) => states.state_name"
                      :options="states"
                      style="white-space: nowrap; "
                    />
                    <span
                      ><small class="text-danger">{{ errors[0] }}</small></span
                    >
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col cols="12" lg="6">
                <b-form-group label="Destination State">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Destination State"
                    vid="state"
                    rules="required"
                  >
                    <vSelect
                      v-model="destinationState"
                      :reduce="(states) => states.state_name"
                      :options="states"
                      class="v-select"
                    />
                    <span
                      ><small class="text-danger">{{ errors[0] }}</small></span
                    >
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-row class="items-center">
              <b-col cols="12" lg="6">
                <b-row>
                  <b-col cols="10">
                    <validation-provider
                      #default="{ errors }"
                      name="Shipment"
                      rules="required"
                      class="w-100"
                    >
                      <b-form-group label="Shipment">
                        <select
                          v-model="form.shipment"
                          class="form-control form-select rounded"
                        >
                          <option value="" disabled>Shipment</option>
                          <option
                            v-for="(address, index) in SHIPMENT_TYPE"
                            :key="index"
                            :value="address"
                          >
                            {{ address.label }}
                          </option>
                        </select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="2" class="align-self-center">
                    <feather-icon
                      v-b-tooltip.click.v-dark
                      title="DHL not part of this Quotation due to the DHL price itself"
                      icon="InfoIcon"
                      size="26"
                      class="text-primary toolTip"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" lg="6" class="mt-1 align-self-center">
                <b-button
                  variant="gradient-primary"
                  class="w-100 box-shadow-1"
                  @click="onSubmit"
                >
                  <span v-if="!isLoading">Quote</span>
                  <span v-else>
                    <b-spinner style="height: 15px; width: 15px" />
                  </span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <hr class="mb-2" />
        <!-- Estimated Price/Proceed to Order -->
        <div class="row align-items-center">
          <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="row my-1">
              <div class="col">
                <span class="d-block">ESTIMATED PRICE:</span>
                <span class="d-block">
                  <h1 v-if="!isLoading">
                    {{ $helpers.formatTotal(unitPrice) }}
                  </h1>
                  <b-spinner v-else variant="primary" class="text-center" />
                </span>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6">
            <b-button
              variant="gradient-primary"
              class="w-100 box-shadow-1"
              @click="onOrder"
              >Proceed to Order</b-button
            >
          </div>
        </div>
        <div v-if="requested" class="mt-2">
          <span class="h1 text-primary font-medium-3">
            {{
              underCoverage
                ? 'Yay! Delivery to this area is available.'
                : errorMessage
            }}
          </span>
        </div>
      </b-card>
    </validation-observer>
  </b-container>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import axios from '@axios';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import { SHIPMENT_TYPE } from '@/common/Constants';

export default {
  components: {
    vSelect,
    VBTooltip,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },
  created() {
    this.getAllStates();
  },
  watch: {
    'form.postcode_origin': function(newPostcode) {
      this.filterOriginStates(newPostcode);
    },
    'form.postcode_destination': function(newPostcode) {
      this.filterDestinationStates(newPostcode);
    }
  },

  data() {
    return {
      SHIPMENT_TYPE,
      OpenIndicator: {
        render: (createElement) => createElement('span', '🔽'),
      },
      underCoverage: false,
      requested: false,
      errorMessage: '',
      unitPrice: '00.00',
      isLoading: false,
      states: [],
      form: { shipment: '' },
      statePostcodes: [
        {
            state_name: 'Perlis',
            postal_code_start: '01000',
            postal_code_end: '02999'
        },
        {
            state_name: 'Kedah',
            postal_code_start: '05000',
            postal_code_end: '09810'
        },
        {
            state_name: 'Pulau Pinang',
            postal_code_start: '10000',
            postal_code_end: '14400'
        },
        {
            state_name: 'Perak',
            postal_code_start: '30000',
            postal_code_end: '36810'
        },
        {
            state_name: 'Perak (Taiping)',
            postal_code_start: '34000',
            postal_code_end: '34000'
        },
        {
            state_name: 'Perak (Ipoh)',
            postal_code_start: '30000',
            postal_code_end: '30000'
        },
        {
            state_name: 'Pahang',
            postal_code_start: '25000',
            postal_code_end: '28800'
        },
        {
            state_name: 'Kuala Lumpur',
            postal_code_start: '50000',
            postal_code_end: '60000'
        },
        {
            state_name: 'Selangor',
            postal_code_start: '40000',
            postal_code_end: '48300'
        },
        {
            state_name: 'Selangor (Klang)',
            postal_code_start: '41000',
            postal_code_end: '41000'
        },
        {
            state_name: 'Negeri Sembilan',
            postal_code_start: '70000',
            postal_code_end: '73500'
        },
        {
            state_name: 'Melaka',
            postal_code_start: '75000',
            postal_code_end: '78300'
        },
        {
            state_name: 'Johor',
            postal_code_start: '79000',
            postal_code_end: '86900'
        },
        {
            state_name: 'Johor North',
            postal_code_start: '80000',
            postal_code_end: '80000'
        },
        {
            state_name: 'Johor South',
            postal_code_start: '86000',
            postal_code_end: '86000'
        },
        {
            state_name: 'Terengganu',
            postal_code_start: '20000',
            postal_code_end: '24300'
        },
        {
            state_name: 'Kelantan',
            postal_code_start: '15000',
            postal_code_end: '18500'
        },
        {
            state_name: 'Sabah',
            postal_code_start: '88000',
            postal_code_end: '91309'
        },
        {
            state_name: 'Sarawak',
            postal_code_start: '93000',
            postal_code_end: '98859'
        },
        {
            state_name: 'Singapore',
            postal_code_start: '01xxxx',
            postal_code_end: '82xxxx'
        },
        {
            state_name: 'Putrajaya',
            postal_code_start: '62000',
            postal_code_end: '62988'
        },
        {
            state_name: 'W. P. Kuala Lumpur',
            postal_code_start: '50000',
            postal_code_end: '60000'
        },
        {
            state_name: 'Wilayah Persekutuan Putrajaya',
            postal_code_start: '62000',
            postal_code_end: '62988'
        }
      ],
      originState: "",
      destinationState: "",
      filteredDestinationStates: [],
      filteredOriginStates: []
    };
  },
  methods: {
    filterOriginStates(search) {
      if (search.length >= 2 && search.length <= 5) {
        const paddedSearch = search.padEnd(5, '0');
        const postcode = parseInt(paddedSearch, 10);
        const possibleStates = this.states.filter(state => {
          return postcode >= parseInt(state.postcode_start, 10) && postcode <= parseInt(state.postcode_end, 10);
        });
        if (possibleStates.length > 0) {
          this.originState = possibleStates[0].state_name;
        }
      }
    },
    filterDestinationStates(search) {
      if (search.length >= 2 && search.length <= 5) {
        const paddedSearch = search.padEnd(5, '0');
        const postcode = parseInt(paddedSearch, 10);
        const possibleStates = this.states.filter(state => {
          return postcode >= parseInt(state.postcode_start, 10) && postcode <= parseInt(state.postcode_end, 10);
        });
        if (possibleStates.length > 0) {
          this.destinationState = possibleStates[0].state_name;
        }
      }
    },
    getOrder() {
      axios;
      this.$http
        .get(`get_order/${this.$route.query.payment_id}`)
        .then((response) => {
          this.isLoading = false;

          // Check the response was a success
          if (response.status === 200) {
            this.orderLabel = response.data.data;
          }
        });
    },
    async getAllStates() {
      const response = await this.$http.get("state");
      if (response.data.status) {
        this.states = response.data.data.map((items) => {
          const matchingPostcode = this.statePostcodes.find(
            item => item.state_name === items.state_name
          );
          return {
            ...items,
            label: items.state_name,
            postcode_start: matchingPostcode ? matchingPostcode.postal_code_start : "",
            postcode_end: matchingPostcode ? matchingPostcode.postal_code_end : ""
          };
        });
        this.filteredDestinationStates = this.states;
        this.filteredOriginStates = this.states;
      } else {
        this.$swal({
          title: "Error !",
          icon: "warning",
          text: response.data.message,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
      }
      this.isLoading = false;
    },
    async onSubmit() {
      this.requested = false;
      this.unitPrice = '00.00';
      this.form.origin_state = this.originState;
      this.form.destination_state = this.destinationState;
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          this.isLoading = true;
          const response = await this.$http.get(
            `quote?postcode_origin=${this.form.postcode_origin}&postcode_destination=${this.form.postcode_destination}&state_destination=${this.form.destination_state}&state_origin=${this.form.origin_state}&order_type=${this.form.shipment.label}`
          );
          if (!response.data.status) {
            this.underCoverage = response.data.status;
            this.errorMessage = response.data.message;
            this.requested = true;
            this.isLoading = false;
            return;
          }

          this.underCoverage = response.data.status;
          this.unitPrice = response.data.quotation.unit_price;
          this.requested = true;
          this.isLoading = false;
        }
      });
    },
    onOrder() {
      if (this.unitPrice > 0) {
        if (this.form.shipment.value === 'Outlet') {
          this.$router.push('/Outlet-order');
        } else {
          this.$router.push('/singapore-order');
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.toolTip {
  cursor: pointer;
  background-color: transparent !important;
}

.v-select .vs__dropdown-menu {
  /* max-width: 300px; Adjust as needed */
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.v-select .vs__selected-options {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-wrap: nowrap;
}
.vs1__listbox {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-wrap: nowrap;
}

</style>
